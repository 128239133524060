import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Calendar.css";
import { useNavigate } from "react-router-dom";

const Booking = () => {
  const [date, setDate] = useState(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [partySize, setPartySize] = useState(1);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const formatDateForSheet = (date) => {
    // Format the date as MM/DD/YYYY
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  };

  useEffect(() => {
    fetchAvailableTimeSlots(date); // Fetch available time slots for the current date on component mount
  }, []); // The empty array ensures this effect runs once on mount

  const fetchAvailableTimeSlots = (date) => {
    setIsLoading(true);
    const dateString = formatDateForSheet(date);

    fetch(
      `https://amordipastaback.onrender.com/api/available-timeslots?date=${dateString}`
    )
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        if (data.availableTimeSlots) {
          setTimeSlots(data.availableTimeSlots);
        } else {
          setTimeSlots([]); // Handle case where no slots are available
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching available time slots:", error);
        // Consider setting an error state here and displaying it to the user
      });
  };

  const onDateChange = (newDate) => {
    setDate(newDate);
    setSelectedTimeSlot(null); // Reset time slot when date changes
    fetchAvailableTimeSlots(newDate);
  };

  // Define the onTimeSlotChange function here
  const onTimeSlotChange = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the data to be sent to the backend
    const bookingData = {
      name: name,
      phoneNumber: phoneNumber,
      date: formatDateForSheet(date), // format the date as YYYY-MM-DD
      timeSlot: selectedTimeSlot,
      partySize: partySize,
      email: email,
    };

    // Send the data to the backend using the fetch API
    fetch("https://amordipastaback.onrender.com/api/bookings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bookingData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          navigate("/reserved");
        } else if (data.error) {
          alert("Error processing the booking: " + data.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Failed to submit reservation.");
      });

    // Reset form
    setName("");
    setPhoneNumber("");
    setSelectedTimeSlot(null);
  };

  return (
    <div className="calendar-container">
      <h2>Select your reservation date</h2>
      <Calendar onChange={onDateChange} value={date} minDate={new Date()} />
      <h3>Available Time Slots</h3>
      {isLoading ? (
        <p>Loading available time slots...</p>
      ) : timeSlots.length > 0 ? (
        <div className="time-slots">
          {timeSlots.map((slot) => (
            <button
              key={slot}
              onClick={() => onTimeSlotChange(slot)}
              disabled={selectedTimeSlot === slot}
              className={`time-slot ${
                selectedTimeSlot === slot ? "selected" : ""
              }`}
            >
              {slot}
            </button>
          ))}
        </div>
      ) : (
        <p>
          No available time slots for this date. Please select another date.
        </p>
      )}

      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          autoFocus={false}
        />
        <label htmlFor="phoneNumber">Phone Number</label>
        <input
          type="tel"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
          autoFocus={false}
        />
        <label htmlFor="Email">Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          autoFocus={false}
        />
        <label htmlFor="partySize">Party Number</label>
        <select
          id="partySize"
          value={partySize}
          onChange={(e) => setPartySize(e.target.value)}
          required
        >
          <option value="">Select party number...</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
        </select>

        <button type="submit" disabled={isLoading || !selectedTimeSlot}>
          Submit Reservation
        </button>
      </form>
    </div>
  );
};

export default Booking;
