import React from "react";
import "./Menu.css"; // Make sure to create a CSS file for styling
import pdfMenuLink from "../../assets/NormalMenu/AmorDiPastaMenu.pdf";
import VeganMenuLink from "../../assets/veganMenu/VeganPlanetMenu.pdf";
import ToShare from "../../assets/NormalMenu/toshare.png";
import ToShareV from "../../assets/veganMenu/toshare.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

function Menu() {
  // Replace these with the actual paths to your menu images
  const regularMenuImage = "/path-to-regular-menu.jpg";
  const veganMenuImage = "/path-to-vegan-menu.jpg";

  return (
    <div className="menu-container">
      <Helmet>
        <title>Menus - Amor Di Pasta & Vegan Planet</title>
        <meta
          name="description"
          content="Discover Amor Di Pasta's menus. Choose from our authentic Italian dishes on the regular menu or explore vegan options with rich flavors of Italy."
        />
        <link rel="canonical" href="https://amordipasta.com/menus" />
      </Helmet>
      <h1>Our Menus</h1>
      <div className="menus">
        <div className="regular-menu">
          <a href={pdfMenuLink} target="_blank" rel="noopener noreferrer">
            <h2>
              Amor Di Pasta Menu{" "}
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </h2>
          </a>
          <img src={ToShare} alt="Regular Menu" className="menu-image" />
        </div>
        <div className="vegan-menu">
          <a href={VeganMenuLink} target="_blank" rel="noopener noreferrer">
            <h2>
              Vegan Planet Menu{" "}
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </h2>
          </a>
          <img src={ToShareV} alt="Vegan Menu" className="menu-image" />
        </div>
      </div>
    </div>
  );
}

export default Menu;
